export const setUserSession = (id) => {
    localStorage.setItem("user_id", id);
}

export const getUserSession = () => {
    return localStorage.getItem("user_id");
}

export const clearUserSession = () => {
    localStorage.removeItem("user_id");
}