import {useDispatch, useSelector} from 'react-redux';
import {add_item, clear_cart, delete_item} from './features/cart_reducer'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '.';
import { useNavigate } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Cart = () => {    

    const cart_data = useSelector(state => state.cart.arr);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const initialStateCartSelItems = {'Item': '', 'Price': '', 'Qty': '', 'Image_Path': ''};

    const[item, setItem] = useState([]);

    const[selItem, setSelItem] = useState(initialStateCartSelItems);

    const handleChange = (event) => {
        if(event.target.name == "Item") {
            let arr = event.target.value;
            let ar = arr.split("~");
            setSelItem({...selItem, 'Item': ar[0], 'Price': ar[1], 'Image_Path': ar[2]});
            console.log(selItem);
        } else {
            setSelItem({...selItem, [event.target.name]: event.target.value});
        }
        
        console.log(selItem);
    }

    const LoadItem = () => {
        axios.get(API_BASE_URL+"list.php").then((response) => {
            console.log(response.data);
            setItem(response.data);
        }).catch((response) => {
            alert(response);
        });
    }

    const addItemFunc = () => {
        if(selItem.Item != '' && selItem.Price != '' && selItem.Qty != '') {
            selItem.Price = selItem.Price * selItem.Qty;
            dispatch(add_item(selItem)); 
            setSelItem(initialStateCartSelItems);
            document.getElementById('Item').value = 0;
        } else {
            alert("Invalid Inputs");
        }
        
    }
    
    useEffect(() => {
        LoadItem();
    }, []);

    var total = 0
    cart_data.forEach(function(item){
        total += item.Price;
    });

    return (

        <div>            
            <center>
                <h1>Cart Page</h1>
                <table border={1}>
                    <thead>
                    <tr key={-2}><td width={50}></td><td width={200}>Item</td><td width={50}>Qty</td><td width={50}>Amount</td><td width={50}></td></tr>
                    </thead>
                    <tbody>
                    {
                        (cart_data.length > 0) ? (
                            cart_data.map((item, index) => (
                                (
                                <tr key={index}>
                                    <td  align='center'> { item.Image_Path != null ? (<LazyLoadImage width="100px" effect="blur" src={API_BASE_URL+item.Image_Path}></LazyLoadImage> ) : ("") } </td>
                                    <td>{item.Item}</td>
                                    <td>{item.Qty}</td>
                                    <td align='right'>{item.Price}</td>                            
                                    <td align='center'><button className='small_button button3' onClick={() => { dispatch(delete_item(index)) }}>X</button></td>
                                </tr>
                                )
                            ))
                        ) : (
                            <tr key={-4}><td align="center" colSpan={5}>Cart is empty</td></tr>
                        )
                                        
                    }
                    <tr key={-3}><td colSpan={4} align='right'><b>Total: {total}</b></td><td></td></tr>
                    </tbody>
                </table>

                <table>
                    <tr>
                        <td>
                        <select name='Item' id='Item' onChange={handleChange}>
                            <option value={0} key={-1}>-- Select --</option>
                            {
                                item.map((iitem, index) => (
                                    <option key={index} value={iitem.Name+"~"+iitem.Price+"~"+iitem.Image_Path}>{iitem.Name}</option>
                                ))
                            }
                        </select>
                        </td>
                        <td>
                            <input type='text' id='Qty' placeholder='Qty' name='Qty' value={selItem.Qty} onChange={handleChange} />
                        </td>
                    </tr>
                </table>
                
                

               
                <button className='button button1' onClick={addItemFunc}>Add</button>

                <button className='button button2' onClick={ () => {navigate('/list') } }>Home</button>
                
                
                {(cart_data.length > 0) ? (
                    <button className='button button3' onClick={ ()=> { dispatch(clear_cart()) } }>Clear Cart</button>
                ) : ( "" )}
            </center>
        </div>        
    )
}

export default Cart;

