import {createSlice} from '@reduxjs/toolkit'

export const cartSlice = createSlice({

    name: 'cart',
    initialState: {
        arr:[],
    },
    reducers: {
        add_item: (state, action) => {
            state.arr.push(action.payload);
        },
        delete_item: (state, action) => {
            state.arr.splice(action.payload, 1);
        },
        clear_cart: (state) => {
            state.arr = [];
        }
    }

});

export const {add_item, clear_cart, delete_item} = cartSlice.actions;
export default cartSlice.reducer;